<template>
  <div>
    <section class="uk-margin">
      <div class="uk-width-1-1">
        <p class="uk-text-bold">Test Results</p>
      </div>
      <section v-for="(test, eKey) in data" :key="`d-${eKey}`">
        <div
          class="uk-grid-collapse"
          style="padding: .2rem 0"
          uk-grid
          v-for="(detail, dKey) in details"
          :key="dKey"
        >
          <div class="uk-width-1-2">{{ test[detail.label] }}</div>
          <div class="uk-width-1-2">
            <div class="uk-grid-collapse" uk-grid>
              <div class="uk-width-auto">:&nbsp;</div>
              <div class="uk-width-expand">
                <div>{{ `${test[detail.value] && test[detail.value].score || 0}/100` }}</div>
                <div>{{ dateFormatter(test[detail.value]) }}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import formatter from "@/utils/formatter";

export default {
    data() {
        return {
            isLoading: false,
            details: [
                { label: 'name', value: 'score' },
            ],
            data: null,
            formatter: formatter,
        };
    },

    async mounted() {
        this.getMitraTestResult();
    },

    methods: {
        ...mapActions({
            getTestResult: 'mitra/getTestResult',
        }),

        dateFormatter(data) {
            if (data && data.created_at)
                return `Test terakhir ${ formatter.dateCompleteNew(data.created_at) }`;

            return "-";
        },

        async getMitraTestResult() {
            this.isLoading = true;
            const data = await this.getTestResult(this.$route.params.mitra_id);

            if (data.result)
                this.data = data.result.scores;

            this.isLoading = false;
        }
    }
};
</script>

<style scoped>
  .avatar {
    width: 100px;
    height: 100px;
    border: 0;
  }
</style>
