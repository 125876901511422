<template>
  <div>
    <testResultView />
    <additionalQuestionsView />
  </div>
</template>

<script>
import testResultView from "@/components/pages/admin/workers/detail-worker/view/test-result.vue";
import additionalQuestionsView from "@/components/pages/admin/workers/detail-worker/view/additional-questions.vue";

export default {
    components: {
        testResultView,
        additionalQuestionsView
    },
    data() {
        return {
            isEdit: false,
            data: {},
        };
    },

    methods: {
        action(isEdit) {
            this.isEdit = isEdit;
        },

        passData(data) {
            this.data = data;
        }
    }
};
</script>
