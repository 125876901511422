<template>
    <div>
        <section v-for="(job_additional_question, jobIdx) in additional_questions" :key="jobIdx" class="uk-margin">
            <section v-if="job_additional_question.question_answers.length > 0">
                <div class="uk-width-1-1">
                    <p class="uk-text-bold">Additional Question(s) for {{ job_additional_question.job.title }} at {{ job_additional_question.company.name }}</p>
                </div>
                <div uk-grid class="uk-grid-collapse uk-width-1-1 uk-margin-remove-right"
                    v-for="(additional_question, index) in job_additional_question.question_answers"
                    :key="index"
                >
                    <div class="uk-width-1-2@l uk-margin-bottom" v-if="additional_question.answer.length > 0">
                        <p class="uk-margin-remove uk-text-bold">Question {{ index + 1 }}</p>
                        <p class="uk-margin-remove">{{ additional_question.question }}</p>
                    </div>
                    <div class="uk-width-1-2@l uk-margin-bottom" v-if="additional_question.answer.length > 0">
                        <p class="uk-margin-remove uk-text-bold">Answer for Question {{ index + 1 }}</p>
                        <div v-if="additional_question.type === 1">
                            <p class="uk-margin-remove">{{ additional_question.answer[0] }}</p>
                        </div>
                        <div v-else-if="additional_question.type === 2">
                            <label>
                                <input type="radio" class="uk-radio" checked disabled />
                                {{ additional_question.answer[0] }}
                            </label>
                        </div>
                        <div v-else>
                            <ul class="uk-list uk-margin-remove">
                                <li class="uk-margin-remove" v-for="(answer, answerIdx) in additional_question.answer" :key="answerIdx">
                                    <label>
                                        <input type="checkbox" class="uk-checkbox" checked disabled />
                                        {{ answer }}
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            isLoading: false,
            additional_questions: []
        };
    },
    async mounted() {
        await this.getMitraAdditionalQuestions();
    },
    methods: {
        ...mapActions({
            getAdditionalQuestions: 'mitra/getAdditionalQuestions',
        }),
        async getMitraAdditionalQuestions() {
            this.isLoading = true;
            const response = await this.getAdditionalQuestions(this.$route.params.mitra_id);
            if (response && response.status === 'OK') {
                this.additional_questions = response.result;
            }
            this.isLoading = false;
        }
    },
};
</script>

<style scoped>

</style>
